.home-chart {
  position: relative;
  width: 400px;
  margin-left: auto;
}

.home-chart-image {
  width: 100%;
  z-index: 0;
}

.home-chart-state {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.white-logo {
  height: 40px;
}

.black-logo {
  height: 40px;
}

.navbar-support {
  background-color: #181E34 !important;
  border-bottom: 1px solid #263051 !important;
}

.navbar-support .navbar-toggler span {
  background-color: #FFFFFF;
}

.slogan {
  font-size: 19px !important;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1280px !important;
  }
}

@media only screen and (max-width: 991px) {
  .home-chart {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {
  .home-chart {
    width: 80%;
  }
}
